<template>
    <div
            id="announcer"
            class="announcer"
            :aria-live="politeness"
            aria-atomic="true"
            role="region"
            aria-label="System Message"
            v-text="content">
    </div>
</template>

<script>
    export default {
        name: 'VueAnnouncer',
        data () {
            return {
                content: '',
                politeness: 'polite'
            }
        },
        created () {
            this.$announcer.data = this.$data
        },
        methods:{
          showError(x){
            this.content = x;
            this.politness = 'assertive';
          },
          showMessage(x) {
            this.content = x;
            this.politness = 'polite';
          }
        }
    }
</script>

<style scoped>
    .announcer {
        position: absolute;
        left: -100000px;
        top: auto;
        width: 1px;
        height: 1px;
        overflow: hidden;
    }
</style>