import Vue from 'vue';
import message from "../../evo/components/sub_components/message_center/message.vue";

Vue.prototype.$toastr.error = function(msg,title,timeout){
    Vue.prototype.$toastr.defaultTimeout = timeout || 3000;
    if(!msg)
        return

    if (typeof newrelic == 'object') {
        try {
            newrelic.noticeError(new Error(JSON.stringify(msg || '{msg: "was blank"}')));
        } catch(error) {
            try {
                newrelic.noticeError(error);
            }catch(ex){
                console.log(ex);
            }
        }
    }
    Vue.prototype.$toastr.e(msg,title);
};
Vue.prototype.$toastr.error_nr = function(msg,title,timeout){
    Vue.prototype.$toastr.defaultTimeout = timeout || 3000;
    if(!msg)
        return
    Vue.prototype.$toastr.e(msg,title);
};

Vue.prototype.$toastr.success = function(msg,title,timeout){
    Vue.prototype.$toastr.defaultTimeout = timeout || 3000;
    Vue.prototype.$toastr.s(msg,title);
};
Vue.prototype.$toastr.warn = function(msg,title,timeout){
    Vue.prototype.$toastr.defaultTimeout = timeout || 3000;
    Vue.prototype.$toastr.w(msg,title);
};
Vue.prototype.$toastr.info = function(msg,title,timeout){
    Vue.prototype.$toastr.defaultTimeout = timeout || 3000;
    Vue.prototype.$toastr.i(msg,title);
};
Vue.prototype.$toastr.tinfo = function(msg,timeout){
    Vue.prototype.$toastr.defaultTimeout = timeout || 3000;
    Vue.prototype.$toastr.i(msg);
};

Vue.prototype.$toastr.login = function(msg,title,timeout){
    Vue.prototype.$toastr.Add({
        title: Vue.prototype.I18n.t('flash.server_con_title'), // Toast Title
        msg: msg, // Message
        clickClose: true, // Click Close Disable
        timeout: 30000, // Remember defaultTimeout is 5 sec..
        position: "toast-top-center", // Toast Position.
        type: "info", // Toast type,
        preventDuplicates: true, //Default is false,
    });
};

Vue.prototype.$toastr.hand = function(msg){
    Vue.prototype.$toastr.Add({
        title: Vue.prototype.I18n.t('flash.student_attention'), // Toast Title
        msg: msg, // Message
        clickClose: true, // Click Close Disable
        timeout: 60000, // Remember defaultTimeout is 5 sec..
        position: "toast-top-left", // Toast Position.
        type: "hand", // Toast type,
    });
};
Vue.prototype.$toastr.checkpoint = function(msg){
    Vue.prototype.$toastr.clearAll();
    Vue.prototype.$toastr.Add({
        msg: msg, // Message
        clickClose: true, // Click Close Disable
        timeout: 1000, // Remember defaultTimeout is 5 sec..
        position: "toast-bottom-left", // Toast Position.
        type: "info", // Toast type,
    });
};

Vue.prototype.$toastr.defaultProgressBar = false;
Vue.prototype.$toastr.defaultPosition = 'toast-bottom-right';
const printStack = function() {
    let err = new Error();
    console.log(err.stack);
};
Vue.prototype.$flash = {
    busy:0,
    sent: 0,
    recv: 0,
    lock: false,
    sr: '0 kb/s',
    rr: '0 kb/s',
    calc_rates: function(){
        /**
        if(Vue.prototype.$flash.sent > 0) {
            Vue.prototype.$flash.sr = (Vue.prototype.$flash.sent / 1000).toFixed(0) + ' kb/s';
            Vue.prototype.$flash.sent = 0;
        } else {
            Vue.prototype.$flash.sr = '';//'0 kb/s';
        }
        if(Vue.prototype.$flash.recv > 0) {
            Vue.prototype.$flash.rr = (Vue.prototype.$flash.recv/1000).toFixed(0)+ 'kb/s';
            Vue.prototype.$flash.recv = 0;
        } else {
            Vue.prototype.$flash.rr = '';//'0 kb/s';
        }
         **/
        Vue.prototype.$flash.updateLabels();
    },
    updateLabels: function(){
        if(Vue.prototype.$swal.isVisible() && Vue.prototype.$swal.isLoading()) {
            let node = document.getElementById('UP_LABEL');
            if(node) {
                //node.innerText = '';//'TX: ' + this.sr;
                if(Vue.prototype.$announcer)
                    Vue.prototype.$announcer.set({msg: 'Transmitting'});
            }
            node = document.getElementById('DL_LABEL');
            if(node) {
                //node.innerText = '';//'RX: ' + this.rr;
                if(Vue.prototype.$announcer)
                    Vue.prototype.$announcer.set({msg:'Receiving'});
            }
        }
    },
    rate_timer: 0,
    loading: function(message) {
        if(this.rate_timer === 0) {
            this.rate_timer = setInterval(this.calc_rates,1000);
        }
        if(Vue.prototype.$swal.isVisible() && Vue.prototype.$swal.isLoading()) {
            Vue.prototype.$flash.updateLoading(message);
            return;
        }
        Vue.prototype.$swal.fire({
            type: 'info',
            title: message || 'Loading',
            //footer: '<div class="container text-center"><i class="fa fa-download data_indicator ml-1" id="LOADER_DOWNLOAD"></i><span id="DL_LABEL">RX: 0kb/s</span> <i class="mr-1 fa fa-clock-o data_indicator" id="LOADER_WAITING"></i><span id="W_LABEL">Idle</span> <i class="mr-1 fa fa-upload data_indicator" id="LOADER_UPLOAD"></i><span id="UP_LABEL">TX: 0kb/s</span></div>',
            footer: '<div class="container text-center"><i class="fa fa-download data_indicator ml-1" id="LOADER_DOWNLOAD"></i><span id="DL_LABEL"></span> <i class="mr-1 fa fa-clock-o data_indicator" id="LOADER_WAITING"></i><span id="W_LABEL"></span> <i class="mr-1 fa fa-upload data_indicator" id="LOADER_UPLOAD"></i><span id="UP_LABEL"></span></div>',
            position: 'center',
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: false,
            keydownListenerCapture: true
        });
        Vue.nextTick( ()=>{
            Vue.prototype.$swal.showLoading();
            Vue.prototype.$flash.updateLoading(message);
        });
    },
    updateLoading: function(message) {
        if(Vue.prototype.$swal.isVisible() && Vue.prototype.$swal.isLoading()) {
            Vue.prototype.$swal.update({title: message});
            if(Vue.prototype.$announcer)
                Vue.prototype.$announcer.set({msg: window.I18n.t('a11y.load_notice',{m:message})});
        }
    },
    dataSent: function(bytes){
        if(Vue.prototype.$swal.isVisible() && Vue.prototype.$swal.isLoading()) {
            let node = document.getElementById('LOADER_UPLOAD');
            if (node) {
                let newone = node.cloneNode(true);
                newone.classList.add('data_sent');
                node.parentNode.replaceChild(newone, node);
            }
        }
        if(bytes) {
            this.sent += bytes;
        }
    },
    dataReceived: function(bytes){
        if(Vue.prototype.$swal.isVisible() && Vue.prototype.$swal.isLoading()) {
            let node = document.getElementById('LOADER_DOWNLOAD');
            if (node) {
                let newone = node.cloneNode(true);
                newone.classList.add('data_received');
                node.parentNode.replaceChild(newone, node);
            }
        }
        if(bytes) {
            this.recv += bytes;
        }
    },
    dataWait: function(duration){
        if(Vue.prototype.$swal.isVisible() && Vue.prototype.$swal.isLoading()) {
            let node = document.getElementById('LOADER_WAITING');
            if (node && this.busy <= 0) {
                node.classList.remove('fa-bolt','fa-clock-o','data_working', 'data_wait');
                let newone = node.cloneNode(true);
                newone.classList.add('data_wait','fa-clock-o');
                let lnode = document.getElementById('W_LABEL');
                if(lnode) {
                    //lnode.innerText = '';//'Waiting';
                }
                if(duration && lnode) {
                    //lnode.innerText = '';//'Waiting .. '+(duration/1000)+'s';
                }
                if(Vue.prototype.$announcer)
                    Vue.prototype.$announcer.set({msg:'Waiting'});
                node.parentNode.replaceChild(newone, node);
            }
        }
    },
    dataIdle: function(){
        if(Vue.prototype.$swal.isVisible() && Vue.prototype.$swal.isLoading()) {
            let node = document.getElementById('LOADER_WAITING');
            this.busy -= 1;
            if (node && this.busy <= 0) {
                this.busy = 0;
                node.classList.remove('data_working', 'data_wait','fa-clock-o','fa-bolt');
                node.classList.add('fa-clock-o');
                let lnode = document.getElementById('W_LABEL');
                if(lnode) {
                    //lnode.innerText = '';//'Idle';
                    //if(Vue.prototype.$announcer)
                    //    Vue.prototype.$announcer.set('Data Idle');
                }
            } else {
                if(node && this.busy > 0) {
                    let lnode = document.getElementById('W_LABEL');
                    if(lnode) {
                        //lnode.innerText = '';//'Request ' + (this.busy) + " remaining";
                        //if(Vue.prototype.$announcer)
                        ///    Vue.prototype.$announcer.set({msg:(this.busy)+' Requests Remaining'});
                    }
                }
            }
        }

    },
    dataBusy: function(){
        if(Vue.prototype.$swal.isVisible() && Vue.prototype.$swal.isLoading()) {
            let node = document.getElementById('LOADER_WAITING');
            this.busy +=1;
            if (node) {
                node.classList.remove('data_working','data_wait','fa-clock-o');
                let newone = node.cloneNode(true);
                newone.classList.add('data_working','fa-bolt');
                let lnode = document.getElementById('W_LABEL');
                if(lnode) {
                    //lnode.innerText = ''; //'Request ' + (this.busy) + " remaining";
                    //if(Vue.prototype.$announcer)
                    //    Vue.prototype.$announcer.set({msg:(this.busy)+' Requests Remaining'});
                }
                node.parentNode.replaceChild(newone, node);
            }
        }
    },
    closeAll: function(){
        clearInterval(this.rate_timer);
        this.rate_timer = 0;
        Vue.prototype.$swal.close();
    },
    close: function(){
        clearInterval(this.rate_timer);
        this.rate_timer = 0;
        Vue.prototype.$swal.close();
        //Vue.prototype.$announcer.set({msg:'Alert Dismissed'});
        if (typeof newrelic == 'object') {
            newrelic.interaction().save();
        }
    },
    error: function(message,cb) {
        Vue.nextTick(function() {
            if (typeof newrelic == 'object') {
                newrelic.noticeError(new Error(message ? JSON.stringify(message) : ''));
            }
            Vue.prototype.$flash.close();
            if(Vue.prototype.$announcer)
                Vue.prototype.$announcer.set({msg: window.I18n.t('a11y.load_error',{m:message}), politeness: 'assertive'});
            Vue.prototype.$swal.fire({
                type: 'error',
                title: message || 'ERROR',
                position: 'center',
                showConfirmButton: true,
                timer: 5000,
                keydownListenerCapture: true,
                confirmButtonClass: 'btn btn-primary',
                buttonsStyling: false,
                onAfterClose: cb || function(){}
            });
        });
    },
    pinfo: function(message){
        if(Vue.prototype.$announcer)
            Vue.prototype.$announcer.set({msg: window.I18n.t('a11y.load_info',{m:message})});
        return Vue.prototype.$swal.fire({
            type: 'info',
            title: message || 'INFO',
            position: 'center',
            keydownListenerCapture: true,
            showConfirmButton: false,
            confirmButtonClass: 'btn btn-primary',
            buttonsStyling: false
        })
    },
    info: function(message,cb) {
        Vue.nextTick(function() {
            Vue.prototype.$flash.close();
            if(Vue.prototype.$announcer)
                Vue.prototype.$announcer.set({msg: window.I18n.t('a11y.load_info',{m:message})});
            Vue.prototype.$swal.fire({
                type: 'info',
                title: message || 'INFO',
                position: 'center',
                keydownListenerCapture: true,
                showConfirmButton: true,
                timer: 5000,
                confirmButtonClass: 'btn btn-primary',
                buttonsStyling: false,
                onAfterClose: cb || function(){}
            });
        });
    },
    warn: function(message) {
        Vue.nextTick(function() {
            Vue.prototype.$flash.close();
            if(Vue.prototype.$announcer)
                Vue.prototype.$announcer.set({msg: window.I18n.t('a11y.load_warn',{m:message})});
            Vue.prototype.$swal.fire({
                type: 'warning',
                title: message || 'WARN',
                position: 'center',
                showConfirmButton: true,
                timer: 5000,
                keydownListenerCapture: true,
                confirmButtonClass: 'btn btn-primary',
                buttonsStyling: false
            });
        });
    },
    success: function(message) {
        Vue.nextTick(function() {
            Vue.prototype.$flash.close();
            if(Vue.prototype.$announcer)
                Vue.prototype.$announcer.set({msg: window.I18n.t('a11y.load_success',{m:message})});
            Vue.prototype.$swal.fire({
                type: 'success',
                title: message || 'SUCCESS',
                position: 'center',
                showConfirmButton: true,
                keydownListenerCapture: true,
                timer: 5000,
                confirmButtonClass: 'btn btn-primary',
                buttonsStyling: false
            });
        });
    }

};
function fixBootstrapModal() {
    let modalNode = document.querySelector('.modal-content[tabindex="-1"]');
    if (!modalNode) return;
    modalNode.removeAttribute('tabindex');
    modalNode.classList.add('js-swal-fixed');
}

// call this before hiding SweetAlert (inside done callback):
function restoreBootstrapModal() {
    let modalNode = document.querySelector('.modal-content.js-swal-fixed');
    if (!modalNode) return;
    modalNode.setAttribute('tabindex', '-1');
    modalNode.classList.remove('js-swal-fixed');
}
window.bootbox = {
    dialog: function(params) {
        //let sz = '50%';
        //if (params.size == 'large')
        //    sz = '100%';
        let config = {
            html: params.message || '',
            title: params.title || '',
            type: 'info',
            grow: 'fullscreen',
            position: 'center',
            reverseButtons: true,
            showConfirmButton: false,
            showCancelButton: false,
            confirmButtonClass: 'btn btn-primary ml-1',
            cancelButtonClass: 'btn btn-outline-danger mr-1',
            buttonsStyling: false,
            cancelButtonText: Vue.prototype.I18n.t('flash.swal_cancel'),
            confirmButtonText: Vue.prototype.I18n.t('flash.swal_confirm'),
        }
        if (params.buttons.cancel) {
            config.cancelButtonText = params.buttons.cancel.label || Vue.prototype.I18n.t('flash.swal_cancel')
            config.showCancelButton = true
        }
        if (params.buttons.confirm) {
            config.confirmButtonText = params.buttons.confirm.label || Vue.prototype.I18n.t('flash.swal_cancel')
            config.showConfirmButton = true
        }
        //Vue.prototype.$flash.close();
        if (Vue.prototype.$announcer)
            Vue.prototype.$announcer.set({msg: window.I18n.t('a11y.load_dialog',{m:params.message})});
        //return Vue.prototype.$swal.fire(config);
        let vm = window.AztecApplication;
        let h = vm.$createElement;
        window.AztecApplication.$bvModal.msgBoxOk(h('div',[h('div',{domProps:{innerHTML: config.html}})]),{
            title: config.title,
            size: 'xl',
            buttonSize: 'sm',
            centered: true,
            okVariant: 'outline-danger',
            okTitle: config.cancelButtonText,
            okOnly: true
        }).then( (x)=>{console.log(x)}).catch( (x)=>{console.log(x)});
        vm.$nextTick(()=>{
            if(window['MathJax'] !== undefined) {
                window.MathJax.Hub.Queue(["Typeset", window.MathJax.Hub])
            }
        })
        /*
        vm.$bvModal.msgBoxOk(vm.$createElement('div',{
            class: ['passage'],
            style:{
                'line-height': '125%',
                'font-size': '1.25em'
            },
            domProps:{
                innerHTML: elm.innerHTML
            }
        }), {
            title: me.I18n.t('learning_player.label.zoom'),
            size: 'lg',
            buttonSize: 'sm',
            okVariant: 'success',
            hideFooter: true,
            centered: true
        })
        **/
        /**
        window.AztecApplication.$emit('vuedals:new', {
            name: 'bootbox_clone',
            title: config.title,
            size:'xl',
            props: {},
            onDismiss(){},
            onClose(){},
            component:{
                name:'box_clone',
                template: "<div><div class='w-100'>"+config.html+"</div><div class='w-100 text-center'><a class='btn btn-outline-danger' @click.stop=\"$emit('vuedals:dismiss')\">"+config.cancelButtonText+"</a></div></div>"
            }
        });
         **/
    }
};
Vue.prototype.$confirm = function(title,message,cancelText,confirmText) {
    Vue.prototype.$flash.close();
    if(Vue.prototype.$announcer)
        Vue.prototype.$announcer.set({msg: window.I18n.t('a11y.load_confirm',{m:message})});
    fixBootstrapModal()
    return  Vue.prototype.$swal.fire({
        type: 'warning',
        html: message || 'CONFIRM',
        title: title || 'TITLE',
        position: 'center',
        showConfirmButton: true,
        showCancelButton: true,
        reverseButtons: true,
        keydownListenerCapture: true,
        confirmButtonClass: 'btn btn-primary ml-1',
        cancelButtonClass: 'btn btn-outline-danger mr-1',
        cancelButtonText: cancelText || Vue.prototype.I18n.t('flash.swal_cancel'),
        confirmButtonText: confirmText || Vue.prototype.I18n.t('flash.swal_confirm'),
        buttonsStyling: false
    }).then(d=>{ restoreBootstrapModal(); return d});
};
Vue.prototype.$bconfirm = function(title,message,cancelText,confirmText) {
    Vue.prototype.$flash.close();
    if(Vue.prototype.$announcer)
        Vue.prototype.$announcer.set({msg: window.I18n.t('a11y.load_confirm',{m:message})});
    return this.$bvModal.msgBoxConfirm(message,{
        titleHtml: title,
        size: 'md',
        buttonSize: 'md',
        okVariant: 'primary',
        okTitle: confirmText || Vue.prototype.I18n.t('flash.swal_confirm'),
        cancelTitle: cancelText || Vue.prototype.I18n.t('flash.swal_cancel'),
        cancelVariant: 'outline-danger',
        footerClass: 'p-2 text-center',
        bodyClass: 'p-4 text-center h4',
        hideHeaderClose: true,
        centered: true
    }).then((x)=>{
        return Promise.resolve({dismiss: x ? false : true})
    }).catch((e)=>{
        return Promise.resolve({dismiss: true})
    });
    /**
    return  Vue.prototype.$swal.fire({
        type: 'warning',
        html: message || 'CONFIRM',
        title: title || 'TITLE',
        position: 'center',
        showConfirmButton: true,
        showCancelButton: true,
        reverseButtons: true,
        keydownListenerCapture: true,
        confirmButtonClass: 'btn btn-primary ml-1',
        cancelButtonClass: 'btn btn-outline-danger mr-1',
        cancelButtonText: cancelText || Vue.prototype.I18n.t('flash.swal_cancel'),
        confirmButtonText: confirmText || Vue.prototype.I18n.t('flash.swal_confirm'),
        buttonsStyling: false
    });
     **/
};

Vue.prototype.$prompt = function(title,question,question_type,input_holder,options,cancelText,confirmText,inputLabel){
    Vue.prototype.$flash.close();
    if(Vue.prototype.$announcer)
        Vue.prototype.$announcer.set({msg:window.I18n.t('a11y.load_prompt',{m:question})});
    fixBootstrapModal();
    return  Vue.prototype.$swal.fire({
        input: question_type,
        inputLabel: inputLabel || 'Response',
        inputOptions: options,
        html: question || 'ANSWER',
        title: title || 'TITLE',
        position: 'center',
        reverseButtons: true,
        inputPlaceholder: input_holder || '',
        showConfirmButton: true,
        showCancelButton: true,
        keydownListenerCapture: true,
        confirmButtonClass: 'btn btn-primary ml-1',
        cancelButtonClass: 'btn btn-outline-danger mr-1',
        cancelButtonText: cancelText || Vue.prototype.I18n.t('flash.swal_cancel'),
        confirmButtonText: confirmText || Vue.prototype.I18n.t('flash.swal_confirm'),
        buttonsStyling: false
    }).then(d=>{ restoreBootstrapModal(); return d});
};
Vue.prototype.$prompt_checkbox = function(title,question,desc,cancelText,confirmText,ival){
    Vue.prototype.$flash.close();
    if(Vue.prototype.$announcer)
        Vue.prototype.$announcer.set({msg: window.I18n.t('a11y.load_prompt',{m:question})});
    fixBootstrapModal();
    return  Vue.prototype.$swal.fire({
        input: 'checkbox',
        inputValue:  ival == undefined ? true : ival,
        inputLabel: question || '',
        html: desc || '',
        title: title || 'TITLE',
        position: 'center',
        inputPlaceholder: question || 'ANSWER',
        reverseButtons: true,
        showConfirmButton: true,
        showCancelButton: true,
        keydownListenerCapture: true,
        confirmButtonClass: 'btn btn-primary ml-1',
        cancelButtonClass: 'btn btn-outline-danger mr-1',
        cancelButtonText: cancelText || Vue.prototype.I18n.t('flash.swal_cancel'),
        confirmButtonText: confirmText || Vue.prototype.I18n.t('flash.swal_confirm'),
        buttonsStyling: false
    }).then(d=>{ restoreBootstrapModal(); return d});
};

Vue.prototype.$prompt_range = function(title,question,min,max,start,cancelText,confirmText){
    Vue.prototype.$flash.close();
    if(Vue.prototype.$announcer)
        Vue.prototype.$announcer.set({msg: window.I18n.t('a11y.load_prompt',{m:question})});
    fixBootstrapModal();
    return  Vue.prototype.$swal.fire({
        input: 'range',
        inputAttributes: {
            min: min,
            max: max,
            step: 1
        },
        inputValue: start,
        html: question || 'ANSWER',
        title: title || 'TITLE',
        inputLabel: 'Number',
        position: 'center',
        reverseButtons: true,
        showConfirmButton: true,
        showCancelButton: true,
        keydownListenerCapture: true,
        confirmButtonClass: 'btn btn-primary ml-1',
        cancelButtonClass: 'btn btn-outline-danger mr-1',
        cancelButtonText: cancelText || Vue.prototype.I18n.t('flash.swal_cancel'),
        confirmButtonText: confirmText || Vue.prototype.I18n.t('flash.swal_confirm'),
        buttonsStyling: false
    }).then(d=>{ restoreBootstrapModal(); return d});

};
// register a global toastr
window.$toastr = Vue.prototype.$toastr;
