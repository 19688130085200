/* global introJs */
import { DIRECTIVES } from './directives';
import timer from './vue-intro-timer';

const DEFAULT_OPTIONS = {
    waitTimeout: 400
};
const isVisible = function(el){
    return el.style.display !== 'none' && el.style.visibility !== 'hidden' && (el.parentElement? isVisible(el.parentElement): true)
};
const Plugin = {
    install(Vue, options) {
        options = Object.assign({}, DEFAULT_OPTIONS, options);

        Vue.prototype.$intro = (...args) => {
            return introJs(...args);
        };

        Vue.prototype.$showHelp = function($el) {
            let steps = [];
            let floatingBlocks = $el.querySelectorAll('[data-floating-intro]');
            let staticSteps = $el.querySelectorAll('[data-intro]');
            for (let i = 0; i < floatingBlocks.length; i++) {
                let floater = floatingBlocks[i];
                if(isVisible(floater)) {
                    let opt_line = {intro: floater.innerHTML};
                    steps.push(opt_line);
                }
            }
            for (let i = 0; i < staticSteps.length; i++) {
                let floater = staticSteps[i];
                if(isVisible(floater)) {
                    let intro_info = floater.dataset.intro;
                    if(floater.dataset.introPrt) {
                        let el = $el.querySelector(floater.dataset.introPrt);
                        if(el)
                            intro_info = el.innerHTML;
                        else
                            intro_info = this.I18n.t('evo.missing_help');
                    }
                    let opt_line = {element: floater, intro: intro_info};
                    if(floater.dataset.position) {
                        opt_line.position = floater.dataset.position;
                    }
                    steps.push(opt_line)
                }
            }
            let intro = introJs();
            intro.setOptions({showButtons: true,showBullets:false,showProgress:true, steps: steps,nextLabel: this.I18n.t('intro.next'),doneLabel: this.I18n.t('intro.done'),skipLabel: this.I18n.t('intro.skip'),prevLabel: this.I18n.t('intro.back')});
            if(steps.length == 0) {
                steps.push({intro: this.I18n.t('intro.no_help')});
            }
            intro.start();
        };


        const Comp = Vue.extend(timer);
        window.__introjsDiscovery = new Comp({
            propsData: options
        });

        Vue.directive('intro', DIRECTIVES.intro);
        Vue.directive('intro-step', DIRECTIVES.step);
        Vue.directive('intro-position', DIRECTIVES.position);
        Vue.directive('intro-tooltip-class', DIRECTIVES.tooltipClass);
        Vue.directive('intro-highlight-class', DIRECTIVES.highlightClass);
        Vue.directive('intro-scroll-to', DIRECTIVES.scrollTo);
        Vue.directive('intro-disable-interaction', DIRECTIVES.disableIntegration);
        Vue.directive('intro-hint', DIRECTIVES.hint);
        Vue.directive('intro-hint-position', DIRECTIVES.hintPosition);
        Vue.directive('intro-autostart', DIRECTIVES.autostart);
        Vue.directive('intro-if', DIRECTIVES.conditional);
    }
};
export default Plugin;