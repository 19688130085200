// Layout, toasts and dialogs JS/css items
import "core-js/stable";
import "regenerator-runtime/runtime";
import Vue from 'vue';
// add toasts for base app shell
import Toastr from 'vue-toastr';
Vue.use(Toastr);
// bootstrap core
import BootstrapVue from 'bootstrap-vue'
Vue.use(BootstrapVue);

//introjs/help overlay
import VueIntro from '../src/plugins/vue-introjs/vue-introjs';
Vue.use(VueIntro);
require('sweetalert2');
require('brace');
require('brace/ext/language_tools');
require('brace/mode/html');
require('brace/mode/javascript');
require('brace/mode/css');
require('brace/theme/chrome');

import VueAnnouncer from '../src/internal_libs/components/vue-announcer.vue'
Vue.component('v-announcer',VueAnnouncer);
Vue.prototype.$announcer = {
    set (message) {
        if (this.data) {
            this.data.content = '';
            this.data.politeness = 'polite';
            Vue.nextTick()
                .then(() => {
                    if(typeof(message) == 'string') {
                        this.data.content = message;
                        this.data.politeness = 'polite'
                    } else {
                        this.data.content = message.msg;
                        this.data.politeness = message.politeness || 'polite'
                    }
                });
        }
    },
    data: null
};

// sweet alerts for when toasts arent enough like confirmation and prompts
//import VueSweetalert2 from 'vue-sweetalert2';
//Vue.use(VueSweetalert2);
import Swal from 'sweetalert2/src/sweetalert2.js';
import 'sweetalert2/dist/sweetalert2.min.css';
const VueSweetalert2 = function () {};
VueSweetalert2.install = function (Vue, options) {
    // adding a global method or property
    let _swal = (options ? Swal.mixin(options) : Swal);
    Vue.swal = _swal;
    // add the instance method
    if (!Vue.prototype.hasOwnProperty('$swal')) {
        Object.defineProperty(Vue.prototype, '$swal', {
            get: function get() {
                return _swal
            }
        });
    }
};
Vue.use(VueSweetalert2);
// vuedals
import {default as Vuedals, Component as Vuedal, Bus as VuedalsBus} from 'vuedals' //from './internal_libs/vuedals/main';
Vue.use(Vuedals);

// dat time picker
import datePicker from '../src/internal_libs/datepicker';
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
Vue.use(datePicker);

require('../src/internal_libs/notifications');
// qrt code generator
import QrcodeVue from '../src/plugins/vue-qrcode'
Vue.component('qrcode', QrcodeVue);