<template lang="pug">
  div.w-100
    b-card(no-body)
      b-card-header
        span.float-left
          | {{I18n.t('a11y.author')}}: {{active_message_from}}
          label.ml-1(v-for="tag in active_message_tags" :style="{'color': $store.getters.customerMessageCenter[convertTag(tag)].c}")
            i.fa.fa-flag(v-b-tooltip="" :title="$store.getters.customerMessageCenter[convertTag(tag)].n")
        span.float-right
          | {{I18n.t('ribbon_bar.labels.date')}}: {{$formatTimeFull(active_message_time)}}
      b-card-body
        div.border-bottom.mb-2
          | {{I18n.t('evo.dash_calendar.event_const_title')}}:&nbsp;
          span(v-html="active_message_subject")
        div#notification_body_div(v-html="active_message_body" v-if="active_component === null")
        component(:is="active_component" :body_object="active_component_props" v-else)

</template>

<script>

export default {
  name: "message",
  props:{
    message_id: {
      type: String,
      required: false,
      default: ()=>{ return "-1"}
    }
  },
  data: function(){
    let me = this;
    return {
      active_message_from: '',
      active_message_body: '',
      active_message_tags:[],
      active_message_subject: '',
      active_message_time: null,
      active_message_title: '',
      active_component: null,
      active_component_props: {},
      active_message_tag: me.I18n.t('ribbon_bar.labels.active_session'),
    }
  },
  beforeRouteLeave: function(to, from, next){
    try {
      this.$event_bus.$emit('message_read');
      this.active_component_props = {details: {}};
      this.active_message_body = '';
    } catch(e){
    }
    next();
  },
  methods:{
    convertTag: function(tag_id) {
      if(tag_id == 1) {
        return 'a';
      }
      if(tag_id == 2) {
        return 'b';
      }
      if(tag_id == 4) {
        return 'c';
      }
      if(tag_id == 8) {
        return 'd';
      }
      if(tag_id == 16) {
        return 'e';
      }
      if(tag_id == 32) {
        return 'f';
      }
      if(tag_id == 64) {
        return 'g';
      }
      if(tag_id == 128) {
        return 'h';
      }
      return 'i';
    },
    processMessage(){
      if(this.active_component !== null) {
        return;
      }
      this.$nextTick(function() {
        let jq = window['jq'] || window.jQuery || window.$;
        let script = jq("#notification_body_div").find('script');
        window._navigator = this;
        if (script[0]) {
          eval(script[0].innerHTML);
        }
      });

    }
  },
  created: function(){
    let me = this;
    if(this.message_id != "-1") {
      me.$axios.get('/api/message/' + this.message_id).then(success => {
        this.active_message_from = success.data.from;
        this.active_message_subject = success.data.subject;
        this.active_message_time = success.data.created_at;
        this.active_message_body = success.data.body;
        this.active_message_tags = success.data.tags;
        this.active_message_title = this.title;
        this.active_component = success.data.component;
        if (success.data.component) {
          this.active_component_props = JSON.parse(success.data.body);
        }
        this.processMessage();
      }, error => {
        me.$flash.error(me.I18n.t('flash.loading_message_error'));
      }).catch(error => {
        me.$flash.error(me.I18n.t('flash.loading_message_error'));
      });
    }
  },
  watch:{
    'message_id': function(){
      // fetch message
      let me = this;
      if(this.message_id != "-1") {
        me.$axios.get('/api/message/' + this.message_id).then(success => {
          this.active_message_from = success.data.from;
          this.active_message_subject = success.data.subject;
          this.active_message_time = success.data.created_at;
          this.active_message_body = success.data.body;
          this.active_message_tags = success.data.tags;
          this.active_message_title = this.title;
          this.active_component = success.data.component;
          if (success.data.component) {
            this.active_component_props = JSON.parse(success.data.body);
          }
          this.processMessage();
        }, error => {
          me.$flash.error(me.I18n.t('flash.loading_message_error'));
        }).catch(error => {
          me.$flash.error(me.I18n.t('flash.loading_message_error'));
        });
      }
    }
  }
}
</script>

<style scoped>

</style>